@charset 'utf-8';

@import 'settings';
@import 'foundation';
//@import 'motion-ui';
//@import "compass";

//@import "../bower_components/foundation/scss/normalize";
// Or selectively include components
@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
@include foundation-badge;
//@include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
//@include foundation-menu;
@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
//@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
//@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

//@include motion-ui-transitions;
//@include motion-ui-animations;

//@import "bower_components/animate.css/animate.min.css";
//@import "bower_components/Morphext/dist/morphext.css";
@import "fonts";

/*
*	project based settings
*/
$default-margin: 2rem;
$default-padding: $default-margin;

$hasFooterWrapper: false;
$footer-minheight: 90px;
$footer-height: 40px;
$footer-content-breakpoint: 763px;
$footer-background-color: false;

/*
	Menu
*/
$hasOffcanvasMenu: true;
$hasDefaultMenu: false;

/*
	Generalsettings
*/
$overlayBackgroundColor: rgba(0, 0, 0, 0.55);
$imageShadow: false; //0px 0px 12px -2px black;

/*
	Header
*/
$headerFrontpageImageHeight: 100vh;
$headerPageImageHeight: 60vh;
$headerBlogImageHeight: $headerPageImageHeight;
$headerSingleImageHeight: 50vh;
$headerSingleImageMinHeight: 380px;
$headerHasImage: false;

/*
	Sidebar
*/
$SidebarAvailable: false;
$SidebarWigetTitleBorder: false; //1px solid black;

/* 
	Post
*/
$postArticleBackgroundColor: $white;
$postSingleEntryAuthorBackgroundColor: #ecf0f1;
$postSingleEntryAuthorPadding: 1rem 2rem;
$postSingleEntryAuthorShadow: 0px 5px 30px 3px rgba(211,211,211,1);;
$postlistTopBottomMargin: 3.5rem;
$postlistEntryFooterBackgroundColor: #f2f1ef;
$post-content-box-color: #fff;

/*
	General Content
*/
$contentImagesTopBottomSpacing: 1rem;
$galleryImageTopBottomSpacing: $contentImagesTopBottomSpacing;

h1
{
	font-weight: 900;
}
h2
{
	font-weight: 700;
}
h3
{
	font-weight: 500;
}


@mixin footer-height-wrapper-mixin()
{
	@if $footer-height < $footer-minheight
	{
		margin-bottom: -$footer-minheight;
		@media only screen and (min-width: $footer-content-breakpoint)
		{
			margin-bottom: -$footer-height;		
		}
	}
	@else
	{
		margin-bottom: -$footer-height;
	}
}
@mixin footer-height-footer-mixin()
{
	@if $footer-height < $footer-minheight
	{

		height: $footer-minheight;
		@media only screen and (min-width: $footer-content-breakpoint)
		{
			height: $footer-height;			
		}
	}
	@else
	{
		height: $footer-height;
	}
}

@mixin container()
{
	padding-left: 15px;
	padding-right: 15px;
	margin-right: auto;
	margin-left: auto;
}

.container
{
	@include container();
}

@mixin center-block()
{
	display: block;
	margin: 0 auto;
}

@mixin header-size($type){
  font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);
  @include breakpoint(medium){
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
  }
}

.center-block
{
	@include center-block();
}

.img-circle, .round-icon, .avatar
{
	border-radius: 50%;
}

img.shadow {
	box-shadow: $imageShadow;
}

.display-inline
{
	display: inline !important;
}
.display-none {
	display: none !important;
}

@mixin button-bordered()
{
	background: $body-background;
	color: $primary-color;
	border: 1px solid $primary-color;
	a
	{
		color: $primary-color;
		&:hover
		{
			color: $white;
			background-color: transparent;
		}
	}
}

.pricing-table {
  border: solid 1px #DDDDDD;
  @extend .no-bullet, .text-center;

  li {
    border-bottom: dotted 1px #DDDDDD;
    font-size: 0.75rem;
    padding: 0.875rem 1.125rem;
    &:last-child {
      border-bottom: 0;
    }
  }

  .title {
    background-color: lighten($primary-color, 25%);
    color: $body-font-color;
    font-size: 0.875rem;
    border-bottom: 0;
    text-align: center;
  }

  .price {
    background-color: #F6F6F6;
    color: #333333;
    font-size: 1.75rem;
    border-bottom: 0;
  }

  .description {
    color: #777777;
  }

  .button {
    margin: 0;
  }
}

.columns {
	.bordered {
		border: 1px solid $light-gray;
		padding: 25px;	
	}
}

//Fontawsone
.fa.medium.circle {
	font-size: 400%;
	width: 8rem;
	height: 8rem;
	color: $third-color;
	/* border: 1px dashed; */
	background-color: white;
	border-radius: 50%;
	border: 2px solid $third-color;
	padding: 30px;
}

.fa.small.circle {
	font-size: 200%;
	width: 4rem;
	height: 4rem;
	color: $third-color;
	/* border: 1px dashed; */
	background-color: white;
	border-radius: 50%;
	border: 2px solid $third-color;
	padding: 15px;
}
