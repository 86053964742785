/*!
Theme Name: Zahnarzt Dr. Kämpfe & Dr. Midderhof
Theme URI: http://zahnarzt-spandau.de
Author: JG-Bits UG (haftungsbeschränkt)
Author URI: https://www.jg-bits.de
Description: Ein individuelles und optimiertes Theme für die Zahnarztpraxis Dr. Kämpfe und Dr. Midderhof
Version: 2.0.1
License: Singledomain
License URI: https://www.jg-bits.de/agb
Text Domain: theme4wp

*/

/*
*	Import settings and components
*/
@import "cms-styles";

//Main Design
html, body
{
	word-wrap: break-word;
	height: 100%;
}
body {
	margin-top: 62px;
	@include breakpoint(medium up) {
		margin-top: 54px;
	}
}

@if ($hasFooterWrapper) {
	.wrapper
	{
		min-height: 100%;
		@include footer-height-wrapper-mixin();
	}
		footer#mainfooter {
	  // .push must be the same height as footer
	  @include footer-height-footer-mixin(); 
	}
	.wrapper:after 
	{
	  content: "";
	  display: block;
	}
	
}

.screen-reader-text
{
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}
.wrap
{
	background: $body-background;
	position: relative;
}

@if ($hasOffcanvasMenu) {
	.title-bar {
		background-color: $primary-color;
		color: $white;
		h1 {
			font-size: 1.25rem;
			margin-bottom: 0;
			color: $white;
		}
		.menu-icon:after {
			background: $body-background !important;
			box-shadow: 0 7px 0 $body-background, 0 14px 0 $body-background !important;
		}
	}	
	
	ul.off-canvas-list {
		list-style-type: none;
		padding: 0;
		margin: 0;
		font-size: 1rem;
		line-height: 1.6;
		margin-bottom: 1.25rem;
		list-style-position: outside;
		font-family: inherit;
		
		li a {
			display: block;
			padding: 0.66667rem;
			color: rgba(0, 0, 0, 0.7);
			border-bottom: 1px solid #bfbfbf;
			-webkit-transition: background 300ms ease;
			transition: background 300ms ease;
			text-decoration: none;
		}
		li a.active {
			background-color: $primary-color !important;
			color: rgba(255, 255, 255, 0.7) !important;		
		}
		li.has-submenu i.fa {
			position: absolute;
			right: 1rem;
			top: 0.5rem;
			font-size: 180%;
			padding: 0 1.5rem;
		}
		li.has-submenu {
			position: relative;
			ul.submenu {
				list-style: none;
			}
		}
	}

	#offCanvasRight {
		padding-top: 2rem;
	}
}

//Main style
.front-page, .page, .error404 {
	#main {
		padding-top: 15px;
		padding-bottom: 15px;
		@include breakpoint(large up) {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}
}

.blog, .archive, .search, .single {
	#main {
		padding-top: 2rem;
		padding-bottom: 1.5rem;
		@include breakpoint(large up) {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}
}

#primary
{
	position: relative;
	background: $body-background;
	@if ($contentImagesTopBottomSpacing)
	{
		img
		{
			@include breakpoint(small only)
			{
				margin-top: $contentImagesTopBottomSpacing;
				margin-bottom: $contentImagesTopBottomSpacing;	
			}
		}	
	}
}

@if ($hasDefaultMenu) {
	#main-nav
	{
		position: fixed;
		top: 0;
		left: 0;
		background: $topbar-background;
		a
		{
			color: $body-font-color;
		}
		width: 100% !important;
		z-index: 1000;
		.site-title
		{
			padding-left: .5rem;
			font-size: 120%;
			margin-bottom: 0;
		}
		.title-bar
		{
			display: flex;
			align-items: center;
			.menu-icon
			{
				margin-left: auto;
			}
		}
	}
}

.blog, .archive, .search
{
	#main
	{
		article
		{
			border-color: rgba(211,211,211,0.75);
			border-width: 2px;
			border-style: solid;
			margin-bottom: $default-margin;
			.entry-title a
			{
				color: $secondary-color;
			}
			.entry-title, .entry-content, .entry-footer {
				padding: 1rem;
			}
		}
	}
}

.blog, .archive, .search, .page, .front-page, .error404
{
	.page-header
	{
		padding-top: $default-padding;
	}
	
	@if ($headerHasImage) {
		header#masthead
		{
			.custom-header
			{
				position: relative;
			}
			.site-branding
			{
				padding-top: 1rem;
				padding-bottom: 1rem;
	/*
				@include breakpoint(large up)
				{
	*/
					position: absolute;
					top: 50%;
					left: 50%;
					/*transform: translateX(-50%) translateY(-50%);*/
					transform: translate(-50%, -50%);
	// 			}
			}
			.custom-header-media
			{
	/*
				@include breakpoint(large up)
				{
	*/
					height: 30vh;
					@include breakpoint(medium up) {
						height: 40vh;
					}
					@include breakpoint(large up) {
						height: 50vh;
					} 
	// 			}
				#wp-custom-header
				{
					width: 100%;
	/*	
					@include breakpoint(large up)
					{
	*/
						position: fixed;	
	// 				}
					@include breakpoint(large up) {
	/*
						top: 50vh;
					    left: 50vw;
					    transform: translate(-50vw, -50vw);
					    width: 100%;
	*/
					}
					img {
						width: auto;
						//height: 50vh;
						@include breakpoint(medium up) {
							width: 100%;
							height: auto;
						}
					}
				}
			}
			.custom-header-secondpart
			{
	/*
				@include breakpoint(large up)
				{
	*/
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
	// 			}
				
			}
		}		
	}
	.entry-title
	{
		@include header-size(h3);
	}
	.entry-header
	{
		.fa
		{
			margin-right: .5rem;
		}
		.categories, .tags
		{
			margin-bottom: 0.75rem;
			margin-top: 0.75rem;
			a
			{
				background: $secondary-color;
				color: white;
				text-decoration: none;
				border-radius: 50px;
				font-size: 1rem;
			}
		}
	}
	.entry-footer
	{
		background-color: $postlistEntryFooterBackgroundColor;
		.fa
		{
			margin-right: .5rem;
		}
		.info-block
		{
			margin-right: 1.5rem;
		}
	}
}
.single
{
	#primary {
		.entry-meta {
		 	background-color: rgb(52, 52, 52);
			padding-top: 1rem;
			padding-bottom: 1rem;
			color: white;
			width: 100%;
			a {
				color: white;
				text-decoration: none;
			}
			span {
				margin-bottom: 0.5rem;
				.text, time {
					font-family: $body-font-family;
				}
			}
		}
	}
	header#masthead
	{
		.custom-header
		{
			position: relative;
		}
		.site-branding
		{
			padding-top: 1rem;
			padding-bottom: 1rem;
/*
			@include breakpoint(large up)
			{
*/
				position: absolute;
				top: 50%;
				left: 50%;
				/*transform: translateX(-50%) translateY(-50%);*/
				transform: translate(-50%, -50%);
			    width: 100%;
// 			}
			.entry-meta
			{
				span
				{
					//width: 100%;
					margin-bottom: .75rem;
					@include header-size(h6);
					a
					{
						color: white;
						
					}
					.text, time
					{
						font-family: $body-font-family;
					}
				}
			}
		}
		.custom-header-media
		{
// 			@include breakpoint(large up)
// 			{
				height: 30vh;
				@include breakpoint(medium up) {
					height: 40vh;
				}
				@include breakpoint(large up) {
					height: 50vh;
				} 	
// 			}
			img
			{
				width: 100%;
			}
			#wp-custom-header
			{
// 				@include breakpoint(large up)
// 				{
					position: fixed;	
					width: 100%;
// 				}
				@include breakpoint(large up) {
/*
					top: 50vh;
				    left: 50vw;
				    transform: translate(-50vw, -50vw);
*/
				    
				}
			}
		}
		.custom-header-secondpart
		{
// 			@include breakpoint(large up)
// 			{
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
// 			}
			
		}
	}
	.fa
	{
		margin-right: .5rem;
	}
	.entry-content {
		padding-bottom: 1rem;
	}
	.entry-author
	{
		background-color: $postSingleEntryAuthorBackgroundColor;
		padding: $postSingleEntryAuthorPadding;
		margin-bottom: 1rem;
		@if ($postSingleEntryAuthorShadow){
			box-shadow: $postSingleEntryAuthorShadow;		
		}
		img {
			@include center-block;
			@include breakpoint(medium up) {
				float: left;
				margin-right: 2rem;
				margin-bottom: 2rem;
			}
		}
	}
}
.single, .blog, .archive, .search, .page, .front-page, .error404
{
	h1, h2, h3, h4, h5, h6
	{
		a
		{
			text-decoration: none;
		}
	}
	
	@if ($headerHasImage) {
		.wrapper
		{
			background-color: $overlayBackgroundColor;
		}
	}
	.custom-header-secondpart
	{
		background-color: $overlayBackgroundColor;
		height: 100%;
		.site-branding
		{
			width: 100%;
			.site-branding-container
			{
				color: white;
				text-align: center;
				h1
				{
					color: white;
				}
				p.site-description {
					@include breakpoint(medium up) {
						font-size: 1.20rem;
					}
					@include breakpoint(large up) {
						font-size: 1.5rem;
					}
				}
				width: 100%;
			}
		}
	}
}

//single-Post
.tags, .categories 
{
	margin-bottom: 0.75rem;
	a
	{
		@extend .label;
		&:hover
		{
			background-color: $primary-color;
		}
		background: grey;
		color: white;
		text-decoration: none;
		border-radius: 50px;
		font-size: 1rem;
	}
}
//comments
#comments
{
	clear: both;
	h3
	{
		span
		{
			border-bottom: 1px solid #000;
		}
	}
}
.singleComment
{
	list-style: none;
	padding-top: 15px;
	padding-bottom: 25px;
}
.comment-author-info
{
	ul
	{
		list-style: none;
	}
	img
	{
		float: left;
		margin-right: 1.25rem;
		background: #F9F7F5;
	}
	a
	{
		text-decoration: underline;
	}
}
.comment-author
{
	@extend h4;
}
.comment-text
{
	clear: both;
}
.reply
{
	a
	{
		text-decoration: underline;
		color: lighten($primary-color, 15%);
	}
}
#respond
{
	input#submit
	{
		@extend .button;
		@extend .small;
	}
}

/*
	Sidebar
*/
#mainsidebar {
	padding-top: 2rem;
}
//Footer
footer#mainfooter
{
	padding-top: $default-padding;
	@if ($footer-background-color) {
		background: $footer-background-color;
	}
	position: relative;
	clear: both;
	padding-bottom: 1.25rem !important;
	.copyright
	{
		margin-top: 2rem;
	}
}
