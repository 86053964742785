@import "project-settings";
/*
*	define here workarounds and fixes for existing styles
*/

/*
*	fix wrong button-color
*/
.button
{
	text-decoration: none;
	a
	{
		color: $button-color;
		
	}
}

.primary
{
	[type='submit'], [type='button']
	{
		@include button();	
	}
}

.button.bordered
{
	@include button-style($body-background, $primary-color, $white);
	@include button-bordered();
}

.menu.medium-horizontal>li
{
	@include breakpoint(medium up)
	{
		display: inline !important;
		float: left;
	}
}
.wp-editor
{
	height: auto !important;
}

.wpcf7-submit
{
	@include button($default-padding/2, $primary-color);
}

iframe
{
	max-width: 100%;
}

.no-js .top-bar {
 display: none;
}

body.logged-in {
	margin-top: 32px + 15px;
}

h1, h2, h3 {
	font-weight: normal !important;
}

.off-canvas-wrapper {
	.title-bar {
		.title-bar-title {
			width: 80%;
			height: 1.5rem;		
		}
	}
 }
